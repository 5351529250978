import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import axios from 'axios';

import bg3 from "../../assect/images/bg/03.jpg"
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

import { FiUser, FiTag, FiShoppingCart } from "../../assect/icons/vander"

// Import slider CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function MemberPage() {
    const [memberNumber, setMemberNumber] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [sliderBanner, setSliderBanner] = useState([]);
    const [loading, setLoading] = useState(false);
    const widgetRef = useRef(null);

    useEffect(() => {
        // Check if user is already logged in
        const loggedInStatus = localStorage.getItem('isLoggedIn');
        if (loggedInStatus === 'true') {
            setIsLoggedIn(true);
        }

        // Fetch slider data
        setLoading(true);
        axios.get('https://api-v1.gondola-ancol.com/api/public/sliders').then((response) => {
            setSliderBanner(response.data.data);
            setLoading(false);
        });

        // Load GOERS widget script
        const script = document.createElement('script');
        script.src = "https://d1ah56qj523gwb.cloudfront.net/widget/1.2.1/widget.min.js";
        script.integrity = "sha384-SYWte49En/51CagiEURWoKO+a4U1ZOvF4U5bqEuKX52fp/ikiP8onM2mZ4hxZfKg";
        script.crossOrigin = "anonymous";
        script.async = true;
        script.onload = initializeWidget;
        document.body.appendChild(script);

        return () => {
            // Clean up the script when component unmounts
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            initializeWidget();
        }
    }, [isLoggedIn]);

    const initializeWidget = () => {
        if (window.GOERS_WIDGET && widgetRef.current) {
            window.GOERS_WIDGET.init();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', 'true');
        
        // Force re-render of the component
        await new Promise(resolve => setTimeout(resolve, 0));
    };

    // Slider settings
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <>
            <Navbar navClass="defaultscroll sticky" logolight={true} menuClass="navigation-menu nav-left nav-light" />
            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg3})` }}>
                <div className="bg-overlay bg-gradient-overlay-2"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <p className="text-white-50 para-desc mx-auto mb-0">Member Area</p>
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Selamat datang Anggota Koperasi Astra</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="section">
                <div className="container">
                    {/* Promotional Banner Slider */}
                    <div className="row justify-content-center mb-5">
                        <div className="col-lg-10">
                            {loading ? (
                                <p>Loading banners...</p>
                            ) : (
                                <Slider {...sliderSettings}>
                                    {sliderBanner.map((banner) => (
                                        <div key={banner.id} className="promotional-banner">
                                            <img src={banner.image} alt={banner.title} className="img-fluid rounded" />
                                            <div className="banner-content">
                                                <h3>{banner.title}</h3>
                                                <p>{banner.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            )}
                        </div>
                    </div>

                    {!isLoggedIn ? (
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="card border-0 rounded shadow">
                                    <div className="card-body">
                                        <h5 className="card-title">Masukkan Nomor Anggota</h5>
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label htmlFor="memberNumber" className="form-label">Nomor Anggota</label>
                                                <div className="form-icon position-relative">
                                                    <FiUser className="fea icon-sm icons" />
                                                    <input
                                                        type="text"
                                                        className="form-control ps-5"
                                                        placeholder="Masukkan Nomor Anggota Anda"
                                                        id="memberNumber"
                                                        value={memberNumber}
                                                        maxLength="6"
                                                        onChange={(e) => setMemberNumber(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary">Validasi</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="card border-0 rounded shadow">
                                    <div className="card-body">
                                        <h5 className="card-title mb-4">Beli Tiket Disini</h5>
                                        <div ref={widgetRef} className="mt-4">
                                            <a id="___GOERS___widget" href="https://goersapp.com/venues/sky-dining-gondola-ancol--skydininggondola" data-background-color="transparent">Gondola Ancol</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <Footer />
        </>
    )
}
